import request from '@/utils/request'


// 查询图标管理列表
export function listIcon(query) {
  return request({
    url: '/gather/gather-icon/list',
    method: 'get',
    params: query
  })
}

// 查询图标管理分页
export function pageIcon(query) {
  return request({
    url: '/gather/gather-icon/page',
    method: 'get',
    params: query
  })
}

// 查询图标管理详细
export function getIcon(data) {
  return request({
    url: '/gather/gather-icon/detail',
    method: 'get',
    params: data
  })
}

// 新增图标管理
export function addIcon(data) {
  return request({
    url: '/gather/gather-icon/add',
    method: 'post',
    data: data
  })
}

// 修改图标管理
export function updateIcon(data) {
  return request({
    url: '/gather/gather-icon/edit',
    method: 'post',
    data: data
  })
}

// 删除图标管理
export function delIcon(data) {
  return request({
    url: '/gather/gather-icon/delete',
    method: 'post',
    data: data
  })
}
